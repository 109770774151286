<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page>
      <template #page-header>
        <h2 class="page-title has-text-color-page-title">Time Off</h2>
        <r-third-level-menu />
      </template>
      <template #action-bar>
        <div class="columns">
          <div class="column is-8">
            <div class="level">
              <div class="level-left">
                <b-button
                  tag="router-link"
                  class="is-command has-red-text"
                  icon-left="plus"
                  to="/request/time-off/create"
                >
                  New
                </b-button>
              </div>
            </div>
          </div>
          <div class="column"></div>
          <div class="column is-3">
            <b-field class="search-container">
              <b-input
                expanded
                v-model="search"
                placeholder="Search"
                type="text"
                @keydown.native.enter="searchIconClick"
                icon-right="magnify"
                @icon-right-click="searchIconClick"
                icon-right-clickable
                :loading="isLoadTimeOffList"
              ></b-input>
            </b-field>
          </div>
        </div>
      </template>
      <template #page-content>
        <div class="columns is-multiline">
          <div class="column is-12">
            <div id="table-request-timeoff">
              <b-table
                :data="timeOffList"
                :per-page="perPage"
                :show-detail-icon="showDetailIcon"
                ref:table
                narrowed
                backend-sorting
                @sort="onSort"
                sticky-header
              >
                <b-table-column
                  field="id"
                  label="Request ID"
                  v-slot="props"
                  centered
                  sortable
                  numeric
                >
                  <span>
                    {{ props.row.id }}
                  </span>
                </b-table-column>

                <b-table-column
                  field="duration"
                  label="Duration"
                  v-slot="props"
                  sortable
                >
                  <span>
                    {{
                      formatDate(new Date(props.row.startDate), 'DD/MM/YYYY')
                    }}
                    -
                    {{ formatDate(new Date(props.row.endDate), 'DD/MM/YYYY') }}
                  </span>
                </b-table-column>

                <b-table-column
                  field="request_reason"
                  label="Reason"
                  v-slot="props"
                  sortable
                >
                  <span>
                    {{
                      props.row.requestReason ? props.row.requestReason : '-'
                    }}
                  </span>
                </b-table-column>

                <b-table-column
                  field="status"
                  label="Status"
                  v-slot="props"
                  sortable
                >
                  <span
                    :class="`td-time-off-request-status ${determineClassByStatus(
                      props.row.requestStatus
                    )}`"
                  >
                    {{ props.row.requestStatus }}
                  </span>
                </b-table-column>

                <b-table-column
                  field="created_at"
                  label="Created At"
                  v-slot="props"
                  sortable
                >
                  <span>
                    {{
                      formatDate(new Date(props.row.createdAt), 'DD/MM/YYYY')
                    }}
                  </span>
                </b-table-column>

                <b-table-column
                  field="action"
                  label="Action"
                  centered
                  v-slot="props"
                >
                  <b-icon
                    icon="pencil"
                    custom-size="mdi-18px"
                    @click.native="openModalEdit(props.row)"
                  />
                  <b-icon
                    @click.native="openSideBar(props.row)"
                    icon="eye"
                    custom-size="mdi-18px"
                  />
                </b-table-column>
              </b-table>
            </div>
          </div>
          <div class="column is-12 has-text-centered" v-if="isLoadMore">
            Please Wait ...
          </div>
        </div>

        <!-- Sidebar to see detail of time off -->
        <!-- fix bug modal edit is not open by checking isRightBarOpen in v-if-->
        <b-sidebar
          v-if="selectedTimeOff != null && isRightBarOpen"
          can-cancel
          type="is-light"
          fullheight
          :fullwidth="false"
          :overlay="true"
          right
          v-model="isRightBarOpen"
        >
          <div class="rightbar-detail-top-content-container">
            <div class="right-bar-detail-top-content">
              <p class="has-text-weight-black is-size-4 title">
                Time Off Details
              </p>
              <b-icon
                icon="close"
                class="x-close-btn"
                custom-size="mdi-18px"
                @click.native="closeRequestTimeOffDetail"
              ></b-icon>
              <img
                class="profile-picture"
                :src="determineProfilePicture(currentUser)"
              />

              <p class="has-text-weight-black is-size-4 full-name">
                {{ currentUser.firstName }} {{ currentUser.middleName }}
                {{ currentUser.lastName }}
              </p>
            </div>

            <div class="columns is-multiline p-4 is-size-6">
              <div class="column is-4">Permission Type</div>
              <div class="column is-8 has-text-weight-bold">
                {{ selectedTimeOff.permissionType }} {{ selectedTimeOff.id }}
              </div>

              <div class="column is-4">Duration Date</div>
              <div class="column is-8 has-text-weight-bold">
                {{
                  formatDate(new Date(selectedTimeOff.startDate), 'DD/MM/YYYY')
                }}
                -
                {{
                  formatDate(new Date(selectedTimeOff.endDate), 'DD/MM/YYYY')
                }}
              </div>

              <div class="column is-4">Status</div>
              <div class="column is-8 has-text-weight-bold">
                {{ selectedTimeOff.requestStatus }}
              </div>

              <div class="column is-4" v-if="selectedTimeOff.files.length > 0">
                Files
              </div>
              <div class="column is-8" v-if="selectedTimeOff.files.length > 0">
                <div class="tags">
                  <span
                    v-for="file in selectedTimeOff.files"
                    :key="file.id"
                    class="tag is-primary tag-small"
                  >
                    <div class="file-tag" @click="openFileUrl(file.url)">
                      {{ file.name }}
                    </div>
                  </span>
                </div>
              </div>

              <div class="column is-4">Reason</div>
              <div class="column is-8 has-text-weight-bold">
                {{
                  selectedTimeOff.requestReason
                    ? selectedTimeOff.requestReason
                    : '-'
                }}
              </div>
              <div class="column is-12">
                <TimeOffApprovalHistoryComponent
                  :approval-history="selectedTimeOff.history"
                />
              </div>
              <div
                class="column is-12"
                v-if="selectedTimeOff.requestStatus !== 'canceled'"
              >
                <b-button expanded @click="cancelTimeOff(selectedTimeOff.id)">
                  Cancel
                </b-button>
              </div>
            </div>
          </div>
        </b-sidebar>
        <!-- Modal to edit time off -->
        <r-page-modal
          :prompt-on-leave="false"
          prompt-message="Are You sure?"
          v-if="isModalEditOpen"
          @hide="closeModalEdit"
          :is-modal="true"
        >
          <template #page-header>
            <h2 class="page-title">Edit TimeOff</h2>
          </template>
          <template #page-content>
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <form
                @submit.prevent="handleSubmit(updateTimeOff)"
                class="is-label-14-px-normal"
                multipart
              >
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <ValidationProvider
                      name="time off category"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-field
                        label="Time off category"
                        class="is-required"
                        :type="errors[0] && 'is-danger'"
                        :message="errors[0]"
                      >
                        <b-select
                          aria-role="list"
                          placeholder="Select category..."
                          v-model="data.timeOffCategory"
                          :expanded="true"
                        >
                          <option
                            v-for="option in timeOffTypeList"
                            :value="option.id"
                            :key="'employment-' + option.id"
                          >
                            {{ option.name }}
                          </option>
                        </b-select>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column is-12">
                    <ValidationProvider
                      name="start_date"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-field
                        label="Start Date"
                        class="is-required"
                        :type="errors[0] && 'is-danger'"
                        :message="errors[0]"
                      >
                        <b-datepicker
                          v-model="data.start_date"
                          placeholder="Select a date..."
                          :min-date="minDate"
                        ></b-datepicker>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column is-12">
                    <ValidationProvider
                      name="end_date"
                      v-slot="{ errors }"
                      rules="required|date_higher_than:@start_date"
                    >
                      <b-field
                        label="End Date"
                        class="is-required"
                        :type="errors[0] && 'is-danger'"
                        :message="errors[0]"
                      >
                        <b-datepicker
                          v-model="data.end_date"
                          placeholder="Select a date..."
                          :min-date="data.start_date"
                        ></b-datepicker>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div
                    class="column is-12"
                    v-if="
                      data.uploadedFiles.length === 0 && data.image === null
                    "
                  >
                    <b-field label="Upload File">
                      <b-upload
                        v-model="data.image"
                        drag-drop
                        expanded
                        accept="image/*"
                      >
                        <section class="section">
                          <div class="content has-text-centered">
                            <p>
                              <b-icon icon="image" size="is-large"></b-icon>
                            </p>
                            <p>
                              Drag image here or
                              <span class="has-red-text">Browse</span>
                            </p>
                          </div>
                        </section>
                      </b-upload>
                    </b-field>
                  </div>

                  <div
                    class="column is-12"
                    v-if="data.uploadedFiles.length > 0"
                  >
                    <b-field label="File already uploaded">
                      <div class="tags">
                        <span
                          v-for="(file, index) in data.uploadedFiles"
                          :key="index"
                          class="tag is-primary"
                        >
                          <div class="file-tag" @click="openFileUrl(file.url)">
                            {{ file.name }}
                          </div>

                          <button
                            class="delete is-small"
                            type="button"
                            @click="deleteUploadedFile(data.id, file)"
                          ></button>
                        </span>
                      </div>
                    </b-field>
                  </div>

                  <div class="column is-4" v-if="data.image != null">
                    <label class="label">File to upload</label>
                  </div>
                  <div class="column is-4" v-if="data.image != null">
                    <div class="tag is-primary">
                      <div class="file-tag">
                        {{ data.image.name }}
                      </div>

                      <button
                        class="delete is-small"
                        type="button"
                        @click="deleteDropFile()"
                      ></button>
                    </div>
                  </div>
                  <div class="column is-12">
                    <ValidationProvider
                      name="request reason"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-field
                        label="Request Reason"
                        class="is-required"
                        :type="errors[0] && 'is-danger'"
                        :message="errors[0]"
                      >
                        <b-input
                          type="textarea"
                          v-model="data.request_reason"
                          placeholder="Please write your message"
                        ></b-input>
                      </b-field>
                    </ValidationProvider>
                  </div>
                </div>

                <b-button
                  native-type="submit"
                  type="is-primary"
                  expanded
                  :loading="isUpdating"
                >
                  Update
                </b-button>
              </form>
            </ValidationObserver>
          </template>
        </r-page-modal>
        <router-view />
      </template>
    </r-page>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment-timezone'
import { showToast, determineFontColorByStatus } from '@/services/util'
import ApiService from '@/services/common/api.service'
import TimeOffApprovalHistoryComponent from './TimeOffApprovalHistoryComponent'

export default {
  components: { TimeOffApprovalHistoryComponent },
  computed: {
    ...mapGetters({
      timeOffList: 'timeOffModule/getTimeoffs',
      timeOffTypeList: 'timeOffModule/getTimeOffTypes',
      currentUser: 'auth/currentUser',
    }),
  },
  data() {
    return {
      isShowModalCreate: false,
      // edit data timeoff
      data: {
        id: '',
        timeOffCategory: null,
        start_date: null,
        end_date: null,
        uploadedFiles: [],
        image: null,
        request_reason: '',
      },
      modalEditKey: 0,

      isUpdating: false,

      minDate: new Date(),
      open: false,
      overlay: true,
      isRightBarOpen: false,
      fullwidth: false,
      right: false,

      isLoadMore: false,
      perPage: 20,
      lastPage: 0,
      total: 0,
      page: 0,

      showDetailIcon: true,
      sortField: 'updated_at',
      sortOrder: 'desc',
      search: '',
      status: '',
      isLoadTimeOffList: false,

      selectedTimeOff: null,
      isModalEditOpen: false,
    }
  },
  methods: {
    ...mapActions({
      actionLoadTimeOffList: 'timeOffModule/fetchMyRequestTimeoffs',
      actionCancelTimeOff: 'timeOffModule/cancelTimeoff',
      actionLoadTimeOffTypes: 'timeOffModule/getTimeOffTypes',
      actionUpdateTimeOff: 'timeOffModule/updateTimeoff',
    }),
    ...mapMutations({
      setTimeOffs: 'timeOffModule/setTimeoffs',
    }),
    determineClassByStatus(status) {
      return determineFontColorByStatus(status)
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    async resetState() {
      this.page = 0
      this.lastPage = 0
      this.setTimeOffs([])
      await this.loadTimeOffList()
    },
    async searchIconClick() {
      await this.resetState()
    },
    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadTimeOffList()
        this.isLoadMore = false
      }
    },
    ...mapMutations({
      removeFileFromTimeOff:
        'timeOffModule/removeSpecificFileFromRequestTimeoff',
    }),

    openSideBar(timeOff) {
      this.selectedTimeOff = timeOff
      this.isRightBarOpen = true
    },

    async deleteUploadedFile(timeOffId, file) {
      let res = await this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete File',
        denyButtonText: 'No',
        customClass: {
          confirmButton: 'button is-primary',
          denyButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: `Are you sure you want to delete ${file.name}? This action cannot be undone.`,
      })

      if (res && res.isConfirmed) {
        try {
          await this.removeFile(timeOffId, file.id)
          showToast('File Deleted', 'is-success', 'is-bottom')

          this.data.uploadedFiles = this.data.uploadedFiles.filter(
            (f) => f.id != file.id
          )
        } catch (err) {
          showToast(err.response.data.message, 'is-danger', 'is-bottom')
        }
      } else if (res && res.isDenied) {
        this.$swal({
          icon: 'error',
          titleText: 'Failed',
          confirmButtonText: 'Go Back Home',
          customClass: {
            confirmButton: 'button is-grey',
          },
        })
      }
    },
    async removeFile(timeOffId, fileId) {
      try {
        await ApiService.delete(`/api/space-roketin/files/${fileId}`)
        this.removeFileFromTimeOff({
          timeOffId,
          fileId,
        })
      } catch (err) {
        console.log(err)
      }
    },
    async loadTimeOffTypes() {
      try {
        await this.actionLoadTimeOffTypes()
      } catch (err) {
        console.log(err)
      }
    },

    deleteDropFile() {
      this.data.image = null
    },

    openFileUrl(fileUrl) {
      let fullUrl = process.env.VUE_APP_API_URL + fileUrl
      window.open(fullUrl, '_blank')
    },

    async updateTimeOff() {
      this.isUpdating = true
      let form = new FormData()

      try {
        if (this.currentUser !== null) {
          form.append('user_id', this.currentUser.id)
        }

        if (this.data.image) {
          form.append('images[]', this.data.image)
        }
        // this.data.image.forEach((element) => {
        //   form.append('images[]', element)
        // })

        form.append(
          'start_date',
          moment(this.data.start_date).format('YYYY-MM-DD')
        )
        form.append('end_date', moment(this.data.end_date).format('YYYY-MM-DD'))
        form.append('timeoff_type_id', this.data.timeOffCategory)
        form.append('request_reason', this.data.request_reason)
        form.append('_METHOD', 'PUT')
        await this.actionUpdateTimeOff({ form, id: this.data.id })
        showToast('Update Success', 'is-success', 'is-bottom')
      } catch (e) {
        console.log(e)
      }
      this.closeModalEdit()
      this.isUpdating = false

      this.resetForm()
    },
    resetForm() {
      this.data = {
        id: '',
        timeOffCategory: null,
        start_date: null,
        end_date: null,
        image: null,
        uploadedFiles: [],
        request_reason: '',
      }
    },
    async cancelTimeOff(id) {
      try {
        await this.actionCancelTimeOff({ id })
      } catch (err) {
        console.log(err)
      }
    },
    openModalEdit(selectedTimeOff) {
      this.data.uploadedFiles = [...selectedTimeOff.files]
      this.data.id = selectedTimeOff.id
      this.data.timeOffCategory = selectedTimeOff.permissionTypeId
      this.data.start_date = new Date(selectedTimeOff.startDate)
      this.data.end_date = new Date(selectedTimeOff.endDate)
      this.data.image = null
      this.data.request_reason = selectedTimeOff.requestReason

      this.modalEditKey += 1
      this.isModalEditOpen = true
    },
    closeModalEdit() {
      this.modalEditKey += 1
      this.selectedTimeOff = null

      this.resetForm()
      this.isModalEditOpen = false
    },
    determineProfilePicture(user) {
      if (!user.profilePictureUrl) {
        if (user.gender === 'M') {
          // male
          return '/images/default-profile-picture-male.png'
        }
        // female
        return '/images/default-profile-picture-female.png'
      }

      return user.profilePictureUrl
    },

    closeRequestTimeOffDetail() {
      this.isRightBarOpen = false
    },
    formatDate(date, format) {
      return moment(date).format(format)
    },
    async loadTimeOffList() {
      this.isLoadTimeOffList = true
      try {
        let response = await this.actionLoadTimeOffList({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          search: this.search,
          status: this.status,
        })
        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isLoadTimeOffList = false
    },
  },
  async mounted() {
    this.setTimeOffs([])
    await this.loadTimeOffList()
    await this.loadTimeOffTypes()
  },
}
</script>
